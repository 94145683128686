/*!
=========================================================
* Mumfy Ads - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* @import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css'; */
#root {
  --bs-primary: #773A89;
  --bs-active-bg: #ead7ff;
  --bs-btn-font-family: "Omnes", sans-serif;
}

body {
  padding: 0 0 30px 0;
  background-color: white;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-variant: initial;
  font-variant: none;
  font-feature-settings: unset;
  font-feature-settings: normal;
}

.App .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #773A89;
  background-color: #753987;
  border-color: #773A89;
  --bs-btn-border-color: #753987;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #854997;
  --bs-btn-hover-border-color: #773A89;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #773A89;
  --bs-btn-active-border-color: #773A89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #773A89;
  --bs-btn-disabled-border-color: #773A89;
  --bs-btn-font-family: "Omnes", sans-serif;
}

.ant-btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #773A89;
  background-color: #753987;
  border-color: #773A89;
  --bs-btn-border-color: #753987;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #854997;
  --bs-btn-hover-border-color: #773A89;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #773A89;
  --bs-btn-active-border-color: #773A89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #773A89;
  --bs-btn-disabled-border-color: #773A89;
}

.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: var(--bs-active-bg);
  border-color: #773A89;
}

.ant-btn-primary:hover {
  background-color: var(--bs-active-bg);
  border-color: #773A89;
}

.App .btn-outline-primary {
  --bs-btn-color: #753987;
  --bs-btn-border-color: #773A89;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #854997;
  --bs-btn-hover-border-color: #854997;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #854997;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}

.layout-dashboard {
  background: transparent;
  text-align: left;
  position: relative;
  overflow-x: hidden;
}

@media (min-width: 992px) {
  .layout-dashboard {
    overflow: auto;
  }
}

.layout-dashboard .ant-layout-sider.sider-primary {
  transition: all 0.25s;
  flex: none !important;

  left: 0;
  z-index: 99;
  overflow: auto;
  margin: 20px 0 0 20px !important;
  padding: 33px 20px;
  height: 100vh;
}

.ant-layout-sider-collapsed {
  transition: all 0.3s ease-out !important;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand img {
  /* height: 30px; */
}

.layout-dashboard .ant-layout-sider.sider-primary hr {
  margin: 18px 0;
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand {
  font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand span {
  vertical-align: middle;
  margin-left: 3px;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
  width: auto;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline {
  border: none;
}

.layout-dashboard .ant-menu {
  background: transparent;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-submenu {
  margin: 0;
  overflow: visible;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
  padding: 0 !important;
  height: auto;
  line-height: normal;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item:after,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-submenu:after {
  display: none;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item:active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .ant-menu-submenu-selected,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-submenu-title:active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .ant-menu-submenu:active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item:active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .ant-menu-submenu-selected,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-submenu-title:active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .ant-menu-submenu:active {
  background-color: transparent;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
  background-color: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  font-weight: 600;
}

.text-decoration-none-collapsed {
  background-color: transparent !important;
  box-shadow: none !important;
  text-decoration: none;
  padding: 10px 0 !important;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
  padding: 10px 16px;
  color: #141414;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}



.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active .icon {
  background-color: var(--bs-primary);
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  background-color: #fff;
  /* box-shadow: 0 4px 6px rgb(0 0 0 / 12%); */
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  vertical-align: middle;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active svg path {
  fill: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu svg path {
  fill: #bfbfbf;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a:hover {
  color: #141414;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item.menu-item-header {
  padding: 10px 16px !important;
  color: #8c8c8c;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
}

.menu-item-header:hover {
  color: inherit;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-zero-width {
  overflow-x: hidden;
  width: 250px;
  margin: 0;
  padding: 33px 0;
}

.layout-dashboard.has-sidebar .ant-layout-sider.sider-primary .aside-footer {
  display: block;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box {
  background-color: #ff5a5f;
  color: #fff;
  box-shadow: none;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box {
  background-color: #fff;
  color: #141414;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  margin-bottom: 8px;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box .icon {
  background-color: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background-color: #ff5a5f;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box h6 {
  color: #fff;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box h6 {
  font-weight: 600;
  font-size: 16px;
  color: #141414;
  margin-bottom: 0;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box p {
  color: #f5f5f5;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box p {
  color: #8c8c8c;
  font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box button {
  background-color: #fff;
  border-color: #f0f0f0;
  color: #141414;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box button {
  margin: 0;
}

.ant-btn-sm,
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
}

.ant-btn-sm {
  border-radius: 4px;
}

.ant-btn-sm,
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
}

.ant-btn-sm {
  border-radius: 4px;
}

.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
  font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .aside-footer {
  padding-top: 100px;
  padding-bottom: 33px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: block;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
  overflow-x: hidden;
}

.layout-dashboard .ant-layout {
  background: transparent;
  position: relative;
  min-height: 100vh;
  width: 100%;
  flex-shrink: 0;
  margin-left: 0 !important;
}

.ant-form label {
  font-size: 16px;
}

.ant-layout-header {
  background: transparent;
  height: auto;
  padding: 16px;
  margin: 10px 4px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.3s;
}

.layout-dashboard .ant-breadcrumb>span .ant-breadcrumb-link a {
  color: #8c8c8c;
}

.layout-dashboard .ant-breadcrumb>span:last-child .ant-breadcrumb-link {
  color: #141414;
}

.layout-dashboard .ant-page-header-heading {
  margin-top: 0;
}

.layout-dashboard .ant-page-header-heading .ant-page-header-heading-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
}

.layout-dashboard .header-control {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 10px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown {
  background: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  min-width: 276px;
  padding: 16px 8px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown ul.ant-dropdown-menu-items {
  margin: 0px;
  padding: 0px;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  min-width: inherit !important;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item {
  padding: 5px 16px;
  margin-bottom: 8px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-avatar {
  width: 36px;
  height: 36px;
  background-color: #fafafa;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-title {
  line-height: normal;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdownt .ant-list-item-meta-description {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-description svg {
  width: 14px;
  height: 14px;
  fill: #8c8c8c;
  vertical-align: middle;
  margin-right: 4px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-description svg path {
  fill: #8c8c8c;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-description span {
  vertical-align: middle;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-avatar .ant-avatar-string {
  top: 6px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-avatar .ant-avatar-string svg {
  width: 22px;
  height: 22px;
}

.h3,
.h4,
h3,
h4 {
  font-weight: 700;
  color: #141414;
}

.ant-list-item-meta-title>a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}

.layout-dashboard .header-control .ant-btn-link {
  height: auto;
  padding: 0 7px;
  margin: 0;
  box-shadow: none;
  color: #8c8c8c;
  line-height: normal;
}

.ant-btn svg {
  margin-right: 5px;
}

.layout-dashboard .header-control svg {
  width: 20px;
  height: 20px;
}

.layout-dashboard .header-control svg path {
  fill: #141414;
}

.layout-dashboard .header-control .btn-sign-in {
  height: auto;
  padding: 0 7px;
  margin: 0;
  box-shadow: none;
  color: #8c8c8c;
}

.layout-dashboard .header-control .btn-sign-in svg {
  margin-right: 4px;
}

.layout-dashboard .header-control svg {
  vertical-align: middle;
}

.layout-dashboard .header-control .header-search {
  width: 213px;
  margin: 0 7px 0 0;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #d9d9d9;
}

.layout-dashboard .header-control .anticon.anticon-search svg {
  width: 16px;
  height: 16px;
}

.layout-dashboard .header-control .header-search svg path {
  fill: #8c8c8c;
}

.settings-drawer .drawer-content>hr {
  margin: 18px 0;
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}

.settings-drawer .drawer-content>h6 {
  font-size: 20px;
  margin-bottom: 0;
}

.layout-dashboard .settings-drawer .drawer-content h6 {
  font-weight: 600;
  color: #141414;
}

.settings-drawer .drawer-content p {
  color: #8c8c8c;
}

.settings-drawer .drawer-content .sidebar-color h6 {
  margin-bottom: 8px;
}

.h6,
h6 {
  font-size: 16px;
}

.drawer-sidebar .ant-drawer-body {
  padding: 0px;
}

.drawer-sidebar .ant-drawer-body aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
  margin: 0px;
}

.layout-dashboard .header-control .header-search .ant-input-suffix .ant-input-search-icon.anticon-loading,
.layout-dashboard .header-control .sidebar-toggler {
  display: block;
}

.layout-dashboard .header-control .btn-sign-in span {
  font-weight: 600;
  vertical-align: middle;
  display: none;
}

/* Old css start */
.site-layout-background {
  background: transparent;
}

header.ant-layout-header.subheader {
  background: transparent;
}

.sidemenu {
  margin-top: 20px;
  margin-left: 20px;
}

.logo img {
  /* width: 32px;
  margin-right: 10px; */
}

.logo {
  margin-bottom: 15px;
}

.logo span {
  font-size: 16px;
  font-weight: 600;
}

hr.horizontal.dark {
  background-image: linear-gradient(90deg,
      transparent,
      rgb(0 0 0 / 12%),
      transparent);
  border: 0px;
}

hr.horizontal {
  background-color: transparent;
}

hr:not([size]) {
  height: 1px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav {
  background: transparent;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li {
  padding: 10px 16px !important;
  height: auto;
  line-height: normal;
  margin: 0px;
  border: 0px;
  font-size: 16px;
  color: #141414;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: visible;
  text-overflow: ellipsis;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li::after {
  display: none;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li span.anticon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #fff;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 16px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected span.anticon {
  background: #ff5a5f;
  color: #fff;
}

/* ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:hover {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li:hover
  span.anticon {
  background: #ff5a5f;
  color: #fff;
} */

.divder {
  display: table;
  width: 100%;
  padding: 10px 15px;
  color: #8c8c8c;
  font-weight: 600;
  margin: 15px 0px;
}

.ant-card.ant-card-bordered.documentation-card {
  border: 0px;
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
}

.p-15 {
  padding: 15px;
}

.card-document {
  width: 32px;
  height: 32px;
  text-align: center;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 19px;
  background: #ff5a5f;
  color: #fff;
  direction: ltr;
  margin-bottom: 15px;
}

.documentation-card h4 {
  margin: 0px;
}

.left-nav {
  /* height: 435px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px; */

  margin-top: 15px;
}

.ant-layout {
  background: #fafafa;
}

.pageheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightside-top {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.signin {
  white-space: nowrap;
  margin: 0px 20px;
}

.signin span.ant-avatar {
  margin-right: 9px;
}

.shadow-lg {
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, 0.15),
    0 8px 9px -5px hsla(0, 0%, 8%, 0.06) !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0px;
}

h4 {
  font-size: 20px;
}

.header-top h4 {
  margin: 0px;
  font-size: 20px;
}

.header-top .subtitle {
  display: block;
  font-weight: normal;
  color: #8c8c8c;
  font-size: 14px;
}

.header-top {
  margin-top: -10px;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.colorside button.ant-drawer-close {
  color: #000;
}

.header-top {
  border-bottom: 1px solid rgb(140 140 140 / 7%);
  padding-bottom: 15px;
}

.rightside-top span.ant-input-affix-wrapper {
  border-radius: 4px;
}

.rightside-top span.ant-input-affix-wrapper span.ant-input-prefix {
  color: #c0c0c0;
}

.setting button.ant-btn {
  padding: 0px;
  line-height: normal;
  background: transparent;
  border: 0px;
  box-shadow: none;
}

button.ant-btn.bell {
  padding: 0px;
  border: 0px;
  background: transparent;
  box-shadow: none;
  position: relative;
}

button.ant-btn.bell strong {
  position: absolute;
  top: -1px;
  right: -5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f5222d;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  color: #fff;
}

.ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  width: 336px;
}

.list-notifaction ul {
  padding: 5px 10px;
  margin: 0px;
}

.list-notifaction ul li {
  display: block;
  width: 100%;

  clear: both;
  font-weight: 400;
  color: #67748e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.list-notifaction ul li a {
  display: block;
  padding: 10px;
  border-radius: 8px;
}

.list-notifaction ul li a:hover {
  color: #252f40;
  background-color: #e9ecef;
}

.list-notifaction ul span.ant-avatar.ant-avatar-image {
  width: 48px;
  height: 48px;
  border-radius: 0.75rem;
}

.list-notifaction ul li {
  padding: 0px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  border-radius: 0.75rem;
}

.list-notifaction .ant-list-item-meta-title {
  font-weight: bold;
  color: #141414;
}

.list-notifaction .ant-list-item-meta-title {
  font-weight: bold;
  color: #141414;
}

.list-notifaction .ant-avatar.ant-avatar-circle {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-notifaction ul li a span.ant-avatar-string {
  line-height: normal;
}

.bnb2 {
  color: #52c41a;
  font-weight: 700;
}

.ant-card.criclebox {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  border: 2px solid #fff;
}

.number span {
  font-weight: 600;
  color: #8c8c8c;
  font-size: 14px;
}

.number h3 {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 30px;
}

.number h3 small {
  font-weight: 600;
  font-size: 14px;
}

.icon-box {
  width: 48px;
  height: 48px;
  text-align: center;
  background: #ff5a5f;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: auto;
  line-height: 55px;
}

.icon-box span {
  color: #fff;
  font-size: 24px;
}

.listpage {
  padding-top: 15px;
}

.bar-chart {
  background: transparent linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 6px #0000001f;

  border-radius: 8px;
}

.chart-vistior {
  margin-top: 30px;
}

.chart-vistior h5 {
  margin: 0px;
  font-weight: 700;
}

.ant-typography.lastweek {
  color: #8c8c8c;
  font-weight: 600;
}

.chart-visitor-count h4 {
  margin: 0px;
  font-weight: 700;
}

.chart-visitor-count span {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}

.linechart h5 {
  font-weight: 700;
  margin: 0px;
}

.linechart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.sales ul li:first-child span.anticon {
  color: #b37feb;
}

.sales ul li:last-child span.anticon {
  color: #ff5a5f;
}

.sales ul li {
  color: #8c8c8c;
  margin-bottom: 3px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.project-ant h4 {
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  color: #141414;
}

span.blue {
  margin-left: 5px;
  color: #ff5a5f;
  font-weight: 600;
}

.project-ant h5 {
  margin: 0px;
  color: #141414;
  font-weight: 700;
}

span.blue {
  margin-left: 5px;
  color: #ff5a5f;
  font-weight: 600;
}

.project-ant {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a {
  display: inline-block;
  padding: 7px 10px;
  color: #595959;
  border: 1px solid #d9d9d9;
  text-align: center;
  border-right: 1px solid transparent;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:last-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0px 5px 5px 0px;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:first-child {
  border-radius: 5px 0px 0px 5px;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:hover {
  color: #ff5a5f;
  border: 1px solid #ff5a5f;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a.active {
  color: #ff5a5f;
  border: 1px solid #ff5a5f;
}

.ant-list-box th {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 8px 0px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 600;
}

.ant-list-box td {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 16px 25px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-column {
  flex-direction: column !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.tootip-img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.avatar-group .tootip-img+.tootip-img {
  margin-left: -10px;
}

.tootip-img:hover {
  z-index: 3;
  cursor: pointer;
}

.title-box h5 {
  margin: 0px;
}

.text-xs {
  color: #8c8c8c;
}

.percent-progress span.ant-progress-text {
  display: flex;
  margin-top: -27px;
  color: #8c8c8c;
  position: relative;
  margin-left: 0px;
}

/* .percent-progress .ant-progress-outer {
  margin-right: 0px;
  padding-right: 0px;
} */

.percent-progress .ant-progress-status-exception .ant-progress-text {
  color: #773a89;
}

.ant-full-box {
  width: 100%;
  border: 1px dashed #d9d9d9;
  padding: 10px;
  height: auto;
  color: #141414;
  border-radius: 10px;
}

.uploadfile {
  margin-top: 15px;
}

.uploadfile .ant-upload.ant-upload-select {
  display: block;
}

.ant-timeline-item-tail {
  left: 7px;
}

.ant-list-box h5 {
  font-size: 14px;
}

.timeline-box h5 {
  margin-bottom: 0px;
  font-weight: 700;
}

.timelinelist h5 {
  font-size: 14px;
  font-weight: 600;
}

.timelinelist span {
  color: #8c8c8c;
  font-size: 12px;
}

.ant-muse span {
  color: #8c8c8c;
  font-weight: 600;
}

.ant-muse h5 {
  margin: 0px 0px 15px !important;
  font-size: 20px;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}

img {
  max-width: 100%;
}

.border10 {
  border-radius: 10px;
}

.py-4 {
  padding: 20px;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.icon-move-right .anticon-right {
  color: #40a9ff;
  margin-left: 2px;
}

.icon-move-right {
  color: #ff5a5f;

  display: block;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.gradent {
  background: url("../images/info-card-2.jpg") no-repeat center top;
  background-size: cover;
  padding: 15px;
  border-radius: 15px;
  color: #fff;
}

.gradent h5 {
  color: #fff;
}

.gradent .ant-typography {
  color: #fff;
  font-size: 20px;
}

.gradent .icon-move-right {
  color: #fff;
}

.gradent .icon-move-right .anticon-right {
  color: #fff;
}

.copyright {
  color: #8c8c8c;
  margin-top: 7px;
}

.copyright .anticon-heart {
  color: #f5222d;
  margin: 0px 5px;
}

.copyright a {
  margin: 0px 5px;
  color: #141414;
  font-weight: 700;
}

.footer-menu ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-menu ul li {
  list-style: none;
}

.footer-menu ul li a {
  padding-block: 0.5rem;
  padding-left: 0;
  display: block;
  color: #8c8c8c;
}

span.ismobile {
  display: none;
  margin-right: 15px;
  cursor: pointer;
}

@media (max-width: 992px) {
  .footer-menu ul {
    justify-content: center;
  }

  footer.ant-layout-footer {
    padding: 0;
    text-align: center;
  }

  .project-ant {
    display: block;
  }

  .ant-filtertabs {
    text-align: right;
  }

  .rightside-top span.ant-input-affix-wrapper {
    width: 200px;
  }

  .rightside-top {
    justify-content: flex-end;
  }

  header.ant-layout-header.subheader {
    padding: 0px 24px 0px 0px;
  }

  main.ant-layout-content.content-ant {
    padding-left: 0px !important;
  }

  .ant-row {
    margin-left: -12px;
    margin-right: -12px;
  }

  span.ismobile {
    display: inline-block;
  }

  .bar-chart {
    width: 100% !important;
    max-width: 100%;
  }

  .full-width {
    max-width: 100% !important;
  }

  .bar-chart {
    width: 100% !important;
  }

  .icon-move-right {
    margin-bottom: 15px;
  }

  .pageheader {
    display: block;
  }

  .ant-cret img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.sidebar-color {
  padding: 1.5rem 0px;
}

.theme-color button {
  margin-right: 8px;
  border: 0px;
  width: 20px !important;
  height: 20px !important;
  padding: 0px;
  box-shadow: none;
  visibility: visible !important;
  line-height: 20px !important;
}

.theme-color button:focus {
  box-shadow: none;
  border-color: transparent;
}

.theme-color button span {
  opacity: 0;
}

.mb-2 {
  margin-bottom: 25px;
}

.sidebarnav-color button {
  width: 100%;
  margin-right: 10px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
  height: 40px;
}

.sidebarnav-color h5 {
  margin: 0px;
}

.sidebarnav-color span.ant-typography {
  color: #8c8c8c;
}

.sidebarnav-color .trans {
  margin-top: 1rem;
  display: flex;
}

.sidebarnav-color button:last-child {
  margin-right: 0px;
}

.ant-docment button {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.viewstar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.viewstar a {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 0em 0.25em 0.25em 0em;
  border: 1px solid #ddddde;
  border-left: 0px;
  color: #141414;
}

.viewstar a:first-child {
  color: #24292e;
  background-color: #eff3f6;
  border-color: #cfd3d6;
  background-image: linear-gradient(180deg, #fafbfc, #eff3f6 90%);
  border-radius: 0.25em 0 0 0.25em;
  border-left: 1px solid #ddddde;
}

.social {
  text-align: center;
  margin-top: 25px;
}

.social button {
  margin: 0px 5px;
  display: inline-flex;
  align-items: center;
}

.ant-thank {
  text-align: center;
  margin-top: 25px;
}

.setting-drwer {
  background: #fff;
  border-radius: 50%;
  bottom: 42px;
  right: 30px;
  font-size: 1.25rem;
  z-index: 990;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 16%);
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 60px;
  position: fixed;
  cursor: pointer;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:active {
  background: transparent;
}

.rightside-top .ant-input {
  height: 30px;
}

.aside-footer {
  padding-top: 100px;
}

.ant-card-body {
  padding: 16px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li svg path {
  color: #bfbfbf;
}

.cardbody .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-card.criclebox .project-ant {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-card.criclebox table th {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.percent-progress .ant-progress-bg {
  height: 3px !important;
  background-color: var(--bs-primary);
}

.uploadfile {
  /* padding-left: 24px;
  padding-right: 24px; */
}

.ant-card.tablespace .ant-card-body {
  padding: 0px;
}

.tablespace .ant-card-head-title {
  font-size: 20px;
  font-weight: 600;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected path {
  color: #fff;
}

.ant-avatar-group .ant-avatar-square {
  border-radius: 6px;
}

.shape-avatar {
  margin-right: 20px;
}

.shape-avatar img {
  border-radius: 4px;
}

.avatar-info h5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 3px;
}

.avatar-info p {
  font-size: 14px;
  font-weight: 400;
  color: #8c8c8c;
  margin: 0px;
}

.ant-table-tbody>tr>td h5 {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 0px;
}

.ant-table-tbody>tr>td p {
  font-size: 14px;
  color: #8c8c8c;
  margin: 0px;
}

.tag-primary {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
}

.ant-employed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8c8c8c;
}

.ant-employed a {
  color: #8c8c8c;
  text-decoration: none;
  font-weight: 600;
}

.tag-badge {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
  background: #8c8c8c;
  color: #fff;
  outline: none;
}

.tag-badge:hover {
  background: #8c8c8c;
  color: #fff;
  border-color: #8c8c8c;
  opacity: 0.9;
}

.ant-avatar-group {
  align-items: center;
}

.mb-24 {
  margin-bottom: 24px;
}

.ant-table-thead>tr>th {
  color: #8c8c8c;
  font-weight: 700;
  background-color: transparent;
  font-size: 12px;
}

.ant-border-space th:before {
  display: none;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  padding: 16px 16px;
}

.tablespace {
  overflow: hidden;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle tfoot>tr>th,
.ant-table.ant-table-middle tfoot>tr>td {
  padding: 16px 25px;
}

.avatar-sm {
  width: 25px;
}

.ant-progress-project {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-progress-project .ant-progress-outer {
  float: right;
}

.ant-progress-project .ant-progress-text {
  margin: 0px 0px 0px 0px;
  color: #8c8c8c;
  font-weight: 600;
}

.semibold {
  font-weight: 600;
  color: #8c8c8c;
}

.text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}

.ant-progress-project .ant-progress {
  margin-right: 15px;
}

.semibold {
  font-weight: 600;
  color: #8c8c8c;
}

.text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}

.ant-progress-project .ant-progress-bg {
  height: 3px !important;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  white-space: nowrap;
}

.h-full {
  height: 100%;
}

@media (min-width: 1200px) {
  .ant-col.col-img img {
    height: 196px;
  }

  /* .bar-chart {
    max-width: 438px;
  } */
  /* .full-width {
    max-width: 658px;
  } */
}

@media (max-width: 1024px) {
  .bar-chart {
    max-width: 100%;
  }

  .full-width {
    max-width: 100%;
  }

  /* .ant-col.mobile-24 {
    margin-bottom: 24px;
  } */
}

body {
  overflow: visible !important;
  width: 100% !important;
}

.card-info-2 .ant-card-body {
  height: 100%;
}

.col-content {
  flex-grow: 1;
  padding: 24px;
  margin-right: 0;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content p {
  font-size: 16px;
}

.ant-layout-footer {
  /* padding: 0px 50px; */
}

/* sign up start */
.layout-default.ant-layout.layout-sign-up header.ant-layout-header {
  background-color: transparent;
  color: #fff;
  margin: 0;
  padding: 0 20px;
  z-index: 1;
}

.layout-default .ant-layout-header {
  display: flex;
}

.header-col.header-nav {
  margin-left: auto;
  width: 100%;
  margin-right: auto;
}

.header-col.header-nav .ant-menu-overflow {
  justify-content: center;
}

.header-col.header-nav .ant-menu-horizontal {
  background: transparent;
  box-shadow: none;
  border: 0px;
}

.layout-default.ant-layout.layout-sign-up {
  padding: 11px;
}

.header-col.header-nav li:after {
  display: none;
}

.sign-up-header {
  height: 550px;
  margin-top: -62.8px;
  padding-top: 137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-size: cover;
  background-position: 50%;
  text-align: center;
}

.header-col.header-brand {
  white-space: nowrap;
}

.header-col.header-brand h5 {
  margin: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.header-col.header-nav {
  color: #fff;
  font-weight: 600;
}

.header-col.header-nav ul li {
  color: #fff !important;
}

.header-col.header-nav ul li img {
  margin-top: -2px;
  margin-right: 5px;
}

.header-col.header-btn button {
  height: 34px;
  padding: 0 16px;
  border-radius: 34px;
  line-height: 33px;
}

.sign-up-header .content {
  padding-top: 40px;
  max-width: 480px;
  margin: auto;
}

.sign-up-header * {
  color: #fff;
}

.sign-up-header .content h1 {
  font-size: 48px;
  color: #fff;
}

.text-lg {
  font-size: 16px;
}

.card-signup .sign-up-gateways .ant-btn {
  margin-right: 10px;
  margin-left: 10px;
  height: 60px;
  width: 70px;
  box-shadow: none;
  border-radius: 6px;
}

.card-signup {
  width: 100%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  margin-top: -190px;
  margin-bottom: 20px;
}

.card-signup h5 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #141414;
  margin: 0px;
}



.card-signup .sign-up-gateways .ant-btn img {
  width: 20px;
}

.sign-up-gateways {
  text-align: center;
}

.m-25,
.mb-25,
.my-25 {
  margin-bottom: 25px;
}

.m-25,
.mt-25,
.my-25 {
  margin-top: 25px;
}

.text-center {
  text-align: center;
}

.font-semibold {
  font-weight: 600;
}

.text-muted {
  color: #8c8c8c;
}

.ant-input {
  border-radius: 6px;
}

.ant-input {
  font-weight: 600;
  color: #8c8c8c;
}

.ant-input {
  height: 40px;
  border: 1px solid #d9d9d9;
}

.font-bold {
  font-weight: 700;
}

.text-dark {
  color: #141414;
}

.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
}

.ant-btn {
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  border-radius: 6px;
}

.layout-sign-up .ant-layout-footer {
  /* padding: 24px 50px; */
}

.layout-default .ant-layout-footer {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: transparent;
  text-align: center;
}

.layout-default .ant-layout-footer .ant-menu-horizontal {
  border: none;
  line-height: 1.5;
  margin: 0 0 30px;
  background-color: transparent;
  font-size: 16px;
}

.layout-default .ant-layout-footer .ant-menu-horizontal {
  text-align: center;
  display: block;
}

.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item-active,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item:hover,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu-active,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu:hover {
  color: #8c8c8c;
  border: none;
}

.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item-active,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-item:hover,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu-active,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal>.ant-menu-submenu:hover {
  color: #262626;
}

.layout-default.ant-layout.layout-sign-up .ant-layout-footer ul li:after {
  display: none;
}

.layout-default.ant-layout.layout-sign-up footer.ant-layout-footer ul li a svg {
  width: 18px;
  height: 18px;
}

.layout-default .ant-layout-footer .menu-nav-social a svg {
  fill: #8c8c8c;
  vertical-align: middle;
}

.layout-default .ant-layout-footer .menu-nav-social a:hover svg {
  fill: #ff5a5f;
}

.layout-default .ant-layout-footer .copyright a {
  color: inherit;
  font-weight: normal;
}

/* signin start csss */
.layout-default.layout-signin header.ant-layout-header {
  display: flex;
  position: relative;
  background-color: #fff;
  color: #141414;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  flex-direction: row;
  padding: 0 50px;
  height: 64px;
  line-height: 64px;
}

.fill-muted {
  fill: #8c8c8c;
}

.layout-default .ant-layout-header .header-brand h5 {
  white-space: nowrap;

  color: #141414;
  margin: 0;
}

.layout-default .ant-layout-header .header-nav a svg {
  margin-right: 5px;
}

.layout-default .ant-layout-header .header-nav a span,
.layout-default .ant-layout-header .header-nav a svg {
  vertical-align: middle;
}

.layout-default .ant-layout-header .header-nav a {
  color: #141414;
  padding: 6px 12px;
  font-weight: 400;
}

.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-item,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-item-active,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-item-selected,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-item:hover,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-submenu,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-submenu-active,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-submenu-selected,
.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-submenu:hover {
  color: #8c8c8c;
  font-weight: 600;
  border: none;
}

.layout-default .ant-layout-header .header-nav .ant-menu-horizontal>.ant-menu-item {
  padding: 0;
}

.sign-in {
  padding: 0 20px;
}

.layout-default .ant-layout-content {
  padding-top: 40px;
}

.font-regular {
  font-weight: 400;
}

.signin h1 {
  font-size: 48px;
  font-weight: 700;
  color: #141414;
}

.signin h5 {
  font-weight: 400;
  color: #8c8c8c;
  font-size: 20px;
  white-space: normal;
}

.ant-form-item,
.ant-checkbox-wrapper {
  font-feature-settings: initial;
  font-variant: initial;
}

.ant-row.ant-form-item.username {
  display: block;
}

.ant-row.ant-form-item.username .ant-col.ant-form-item-label .ant-form-item-required:before {
  display: none;
}

.ant-row.ant-form-item.username .ant-col.ant-form-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}

.signin .ant-form-item-control-input button.ant-switch {
  margin-right: 5px;
}

.text-dark {
  color: #141414;
}

.sign-img img {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: block;
}

.layout-signin {
  background: #fff;
}

.layout-signin footer.ant-layout-footer svg {
  width: 18px;
  height: 18px;
}

.layout-signin footer.ant-layout-footer ul li:after {
  display: none;
}

.layout-default .ant-layout-footer .copyright {
  font-size: 16px;
  color: #8c8c8c;
}

.row-col .ant-row {
  margin-left: 0px;
  margin-right: 0px;
}

.layout-default.ant-layout.layout-sign-up .header-col.header-brand h5 {
  color: #fff;
}

.layout-default.ant-layout.layout-sign-up .header-col.header-nav a {
  color: #fff;
}

.layout-default.ant-layout.layout-sign-up .header-col.header-nav svg path.fill-muted {
  fill: #fff;
}

/* sign in end */

@media (min-width: 768px) {
  .sign-img img {
    margin: 0;
  }

  .card-signup {
    margin-bottom: 120px;
  }

  .card-signup .sign-up-gateways .ant-btn {
    width: 100px;
  }
}

/* end sign up */

.rightside-top .signin {
  display: inline-flex;
}

.rightside-top .signin svg {
  margin-right: 5px;
}

button.ant-btn.bell strong {
  position: absolute;
  top: -1px;
  right: -9px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #f5222d;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  color: #fff;
}

/* old css end */

.layout-dashboard .ant-layout-footer {
  background: transparent;
  /* margin: 0 20px 20px 20px; */
  padding: 0;
}

.layout-dashboard .ant-layout-content {
  padding: 0;
  margin: 0 20px 0;
}

.m-10,
.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.ant-table-tbody>tr>td h6 {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}

.layout-dashboard .header-control .header-search input.ant-input {
  height: auto;
}

small.redtext {
  color: red;
}

.full-width path {
  fill: transparent;
}

.bar-chart line.apexcharts-xaxis-tick {
  stroke: transparent;
}

section.ant-layout.layout-content .ant-layout-content.content-ant {
  padding: 0px !important;
}

.layout-dashboard .ant-card {
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
}

.card-credit {
  background-image: url("../images/info-card-3.jpg");
  background-position: 50%;
  background-size: cover;
  border: none;
}

.card-credit * {
  color: #fff;
}

.card-credit .card-number {
  word-spacing: 10px;
  font-weight: 600;
  margin-bottom: 45px;
  font-size: 20px;
}

.ant-card-head-wrapper {
  min-height: 72px;
}

.card-credit .ant-card-body {
  padding-top: 15px;
}

.card-credit .card-footer {
  display: flex;
  align-items: flex-end;
}

.m-auto,
.ml-auto {
  margin-left: auto !important;
}

.m-30,
.mr-30,
.mx-30 {
  margin-right: 30px !important;
}

.card-credit .card-footer h6,
.card-credit .card-footer p {
  margin-bottom: 0;
}

.card-credit .col-logo img {
  max-width: 50px;
}

.card-credit .card-footer h6 {
  font-weight: 700;
}

.width-100 {
  width: 100%;
}

.p-20 {
  padding: "0px 20px 0px 0px";
}

.layout-default .ant-layout-content.p-0 {
  padding: 0px;
}

.pt {
  padding-top: 0px;
}

.widget-2 .icon {
  width: 64px;
  height: 64px;
  margin: auto;
  background-color: #ff5a5f;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.widget-2 .ant-statistic-title h6 {
  font-weight: 600;
}

.widget-2 .ant-statistic-title p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.widget-2 .ant-statistic-content {
  font-family: open sans, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 12px 16px;
  border-top: 1px solid #f5f5f5;
  text-align: center;
}

.widget-2 .ant-statistic-title {
  text-align: center;
  padding: 16px;
}

.widget-2 .ant-card-body {
  padding: 0;
}

.widget-2 .icon img {
  width: 30px;
}

.ant-row-flex-middle {
  align-items: center;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.payment-method-card.ant-card {
  box-shadow: none;
  word-spacing: 10px;
}

.payment-method-card.ant-card img {
  width: 40px;
}

.payment-method-card.ant-card .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-method-card.ant-card .ant-btn {
  margin-right: -20px;
}

.ant-btn.ant-btn-link.ant-edit-link {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  box-shadow: none;
}

.ant-btn.ant-btn-link.ant-edit-link img {
  width: 20px;
}

.payment-method-card.ant-card .ant-btn {
  margin-right: -20px;
}

.fill-gray-7 {
  fill: #8c8c8c !important;
}

.payment-method-card.ant-card h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #141414;
}

.m-0 {
  margin: 0px;
}

.ant-card.ant-card-bordered.payment-method-card .ant-card-body {
  padding-top: 16px;
}

.invoice-list .ant-list-item,
.transactions-list .ant-list-item {
  padding: 11px 0;
  border-bottom: 0;
}

.ant-invoice-card .ant-card-body {
  padding-top: 0px;
}

.ant-invoice-card .ant-card-head {
  padding-left: 16px;
}

.invoice-list .ant-list-item-meta-title,
.transactions-list .ant-list-item-meta-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}

.invoice-list .ant-list-item-meta-description,
.transactions-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}

.invoice-list .ant-list-item-action .ant-btn,
.transactions-list .ant-list-item-action .ant-btn {
  font-weight: 600;
  color: #141414;
  box-shadow: none;
}

.ant-btn>svg,
.ant-btn>svg+span {
  vertical-align: middle;
}

.invoice-list .amount,
.transactions-list .amount {
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
}

.amount {
  font-size: 14px;
  font-weight: 800;
  color: #000000;
}

.card-billing-info.ant-card {
  box-shadow: none;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
}

.ant-descriptions .ant-descriptions-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 14px;
}

.fill-danger {
  fill: #773a89;
}

.card-billing-info.ant-card .darkbtn {
  color: #8c8c8c;
}

.col-action {
  display: flex;
}

.card-billing-info .col-action button {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
  box-shadow: none;
}

.ant-descriptions .ant-descriptions-row>td,
.ant-descriptions .ant-descriptions-row>th {
  padding-bottom: 6px;
}

.ant-descriptions .ant-descriptions-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #8c8c8c;
}

.ant-descriptions .ant-descriptions-item-content {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}

.ant-descriptions .ant-descriptions-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 14px;
}

.card-billing-info.ant-card .ant-descriptions-header {
  margin-bottom: 0px;
}

.card-header-date {
  margin-bottom: 0;
  font-weight: 700;
  color: #8c8c8c;
}

.card-header-date>* {
  vertical-align: middle;
}

.card-header-date svg {
  margin-right: 5px;
}

.transactions-list .amount {
  font-size: 16px;
  font-weight: 700;
}

.text-light-danger {
  background: #fde3cf;
  color: #f56a00;
}

.text-fill {
  color: #52c41a;
  background: #edf9e7;
}

.text-danger {
  color: #773a89;
}

.text-success {
  color: #52c41a;
}

.transactions-list .ant-list-item-meta {
  align-items: center;
}

.text-warning {
  background: rgb(255, 252, 231);
  color: #fadb14;
}

.text-warning-b {
  font-size: 14px;
  font-weight: 600;
  color: #fadb14;
}

.ant-card.ant-list-yes .ant-list-header {
  border: 0px;
}

.ant-card.ant-list-yes .ant-list-header h6 {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
  margin: 6px 0;
}

.ant-list.ant-list-split.transactions-list.ant-newest .ant-list-header {
  padding-top: 0px;
}

.profile-nav-bg {
  height: 300px;
  margin-top: -137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-size: cover;
  background-position: 50%;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile span.ant-page-header-heading-title {
  color: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile span.ant-breadcrumb-separator {
  color: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile span.ant-breadcrumb-link {
  color: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile .header-control svg path {
  fill: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile .btn-sign-in {
  color: #fff;
}

.layout-profile .ant-layout-header {
  margin: 10px 20px;
}

.card-profile-head .ant-card-head {
  border-bottom: 0px;
  padding: 0px 16px;
}

.card-profile-head {
  margin: -53px 0 24px;
}

.card-profile-head .ant-card-head .ant-avatar {
  box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin: 0;
}

.card-profile-head .ant-card-head .avatar-info {
  margin-left: 16px;
}

.ant-card-head-title p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 0;
}

.card-profile-head .ant-card-head .avatar-info h4 {
  font-size: 24px;
}

.card-profile-head .ant-card-head .avatar-info h4 {
  font-size: 24px;
}

.card-profile-head .ant-radio-group .ant-radio-button-wrapper {
  height: 2rem;
  font-weight: 700;
}

.ant-radio-button-wrapper {
  color: #595959;
}

.layout-profile .ant-card-head {
  padding: 0 16px;
}

.settings-list {
  list-style: none;
  padding: 0;
}

.settings-list>li:first-child {
  padding-top: 0;
}

.settings-list>li {
  padding: 12px 0;
}

.settings-list>li h6 {
  font-size: 12px;
  color: #8c8c8c;
  margin: 0;
  font-weight: 700;
}

.settings-list>li span {
  margin-left: 16px;
  color: #141414;
}

.ant-btn-link {
  box-shadow: none;
}

.card-profile-information hr {
  opacity: 0.2;
}

.p-5,
.pr-5,
.px-5 {
  padding-right: 5px !important;
}

.p-5,
.pl-5,
.px-5 {
  padding-left: 5px !important;
}

.m-5,
.mr-5,
.mx-5 {
  margin-right: 5px !important;
}

.m-5,
.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.card-profile-information .ant-descriptions .ant-descriptions-row>td,
.card-profile-information .ant-descriptions .ant-descriptions-row>th {
  padding-bottom: 16px;
}

.conversations-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}

.conversations-list .ant-avatar {
  border-radius: 10.5px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
}

.conversations-list .ant-list-item {
  padding: 14px 0;
}

.ant-card-head-title p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 0;
}

.ant-card-head-title h6 {
  margin-bottom: 0;
}

.card-project.ant-card {
  box-shadow: none;
  border: none;
}

.card-project.ant-card .ant-card-cover img {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
}

.card-project.ant-card .card-tag,
.card-project.ant-card p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 5px;
}

.card-project.ant-card h5 {
  font-weight: 600;
  font-size: 20px;
}

.card-project.ant-card .card-tag,
.card-project.ant-card p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 5px;
}

.card-project.ant-card p {
  margin-bottom: 0;
}

.card-project.ant-card .ant-card-body {
  padding: 16px 0 0;
}

.card-project.ant-card .card-footer {
  margin-top: 22px;
}

.avatar-chips .ant-avatar-image {
  border: 1px solid #fff;
}

.avatar-chips .ant-avatar-image:hover,
.avatar-chips .ant-space-item:hover {
  z-index: 1;
}

.projects-uploader {
  height: 100%;
  border-radius: 8px;
  border-color: #d9d9d9;
}

.projects-uploader .ant-upload {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 8px;
  padding: 8px;
}

.projects-uploader .ant-upload-list.ant-upload-list-picture-card {
  height: 100%;
}

.projects-uploader .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}

.projects-uploader svg {
  width: 20px;
  height: 20px;
}

.text-right {
  text-align: right;
  float: right;

}

.layout-dashboard-rtl {
  background: transparent;
  text-align: right;
  direction: rtl;
  position: relative;
  overflow-x: hidden;
}

.layout-dashboard-rtl .ant-layout-sider.sider-primary .ant-menu-item .icon,
.layout-dashboard-rtl .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
  margin-left: 11px;
  margin-right: 0;
}

.layout-dashboard-rtl .ant-timeline-item-tail {
  right: 6.5px;
  left: auto;
}

.layout-dashboard-rtl .ant-timeline-item-content {
  margin: 0 33px 0 0;
}

.ant-timeline-item-head {
  width: 15px;
  height: 15px;
  border-width: 3px;
}

.layout-dashboard-rtl .ant-radio-button-wrapper:first-child {
  border-right-width: 1px;
  border-radius: 0 6px 6px 0;
}

.layout-dashboard-rtl .ant-list-box td {
  text-align: right;
}

.layout-dashboard-rtl .ant-space-horizontal {
  direction: ltr;
}

.layout-dashboard-rtl .ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-right: 8px;
  margin-left: 0px;
}

.layout-dashboard-rtl .setting-drwer {
  left: 30px;
  right: auto;
}

.layout-dashboard-rtl ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  text-align: right;
}

.layout-dashboard-rtl .avatar-group.mt-2 {
  direction: ltr;
}

.ant-list-box table tr:hover td {
  background: #fafafa;
}

.layout-dashboard-rtl .icon-box {
  margin-left: 0px;
  margin-right: auto;
}

.layout-dashboard-rtl .m-10,
.layout-dashboard-rtl .mr-10,
.layout-dashboard-rtl .mx-10 {
  margin-left: 10px !important;
}

.layout-dashboard-rtl .ant-progress-show-info .ant-progress-outer {
  margin-right: 0px;
  padding-right: 0px;
}

.layout-dashboard-rtl .ant-badge-count,
.layout-dashboard-rtl .ant-badge-dot,
.layout-dashboard-rtl .ant-badge .ant-scroll-number-custom-component {
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

.layout-dashboard-rtl .header-control .btn-sign-in svg {
  margin-left: 4px;
  margin-right: 0px;
}

.ant-drawer.drawer-sidebar.drawer-sidebar-rtl aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
  left: auto;
  right: 0px;
  direction: rtl;
}

.layout-dashboard.layout-dashboard-rtl .header-control span.ant-badge {
  margin: 0px 7px;
}

.layout-dashboard.layout-dashboard-rtl span.ant-input-prefix {
  margin-right: 0px;
  margin-left: 4px;
}

.layout-dashboard-rtl .ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl button.ant-drawer-close {
  left: 0px;
  right: auto;
  margin-right: 0px;
  margin-left: var(--scroll-bar);
  padding-left: calc(20px - var(--scroll-bar));
}

.layout-dashboard-rtl .ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl button.ant-drawer-close {
  left: 0px;
  right: auto;
  margin-right: 0px;
  margin-left: var(--scroll-bar);
  padding-left: calc(20px - var(--scroll-bar));
}

.settings-drawer.settings-drawer-rtl .sidebarnav-color button {
  margin-right: 0px;
  margin-left: 10px;
}

.settings-drawer.settings-drawer-rtl .viewstar a:first-child {
  border-radius: 0em 0.25em 0.25em 0;
  border-right: 1px solid #ddddde;
}

.settings-drawer.settings-drawer-rtl .viewstar a {
  border-radius: 0.25em 0 0 0.25em;
  border: 1px solid #ddddde;
  border-right: 0px;
}

.social svg path {
  fill: #fff;
}

.settings-drawer.settings-drawer-rtl .social svg {
  margin-right: 0px;
  margin-left: 5px;
}

.social svg {
  width: 15px;
  height: 15px;
}

.layout-dashboard .ant-affix .ant-layout-header {
  background: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  margin: 10px 20px;
  z-index: 10;
}

aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary.active-route a.active {
  background: transparent;
  box-shadow: none;
  width: 250px !important;
}

.uploadfile.pb-15 {
  padding-bottom: 16px;
}

.ant-row.ant-form-item.aligin-center .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
}

.uploadfile.shadow-none .ant-upload.ant-upload-select.ant-upload-select-text .ant-btn.ant-full-box {
  box-shadow: none;
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
}

button.ant-btn.ant-btn-dashed.ant-full-box svg {
  width: 16px;
  height: 16px;
}

.ant-btn-dashed.active,
.ant-btn-dashed:active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile .header-control span.ant-input-affix-wrapper.header-search .anticon-search svg path {
  fill: #8c8c8c;
}

/*  */

html {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  --primary-background-color: var(--bs-primary);

  overflow: hidden;
}

::-webkit-scrollbar {
  /* display: none; */
}

/* * {
	outline: solid 1px red;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input#otp_entered_by_user {
  width: 90%;
  margin-left: 10px;
  height: 50px;
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: #f8efef;
  border-color: rgba(252, 251, 251, 0.1);
}

.doMore {
  margin-top: 30px;
}

.headerLogo {
  /* position: absolute; */
  width: 240px;
  top: 12px;
  cursor: pointer;
}

.img-responsive {
  width: 35%;
  height: 35%;
}

.logo {
  height: 48px;
  top: 14px;
  margin-left: 2%;
}

@font-face {
  font-family: 'New Spirit';
  src: url('../fonts/New\ Spirit\ Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Omnes';
  src: url('../fonts/Omnes\ Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

h4,
h5,
h6,
label {
  font-family: 'Omnes', sans-serif;
}

p {
  font-family: 'Omnes', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'New Spirit', sans-serif;
}

.text-center h2 {
  font-family: 'New Spirit', sans-serif;
}

.text-center h3 {
  font-family: bl-bold;
}

.text-left h3 {
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
}

h-btn {
  border-radius: 12px;
}

h5 {
  font-family: bl-regular;
  font-weight: bold;
}

.nav-link {
  padding-left: 0px !important;
  padding-right: 50px !important;
}

.custom-btn {
  padding: 10px;
  width: 207px;
  height: 55px;
  border-radius: 12px;
}

/* .custom-btn:hover {
  background-color: #ed4d41 !important;
} */

.brandImage {
  width: 90%;
  overflow: hidden;
  height: 107%;
}

.text-left p {
  padding: 20px;
  padding-left: 10px;
  padding-top: 0px;
}

.dotSlider {
  margin-top: 40px;
}

.lgn {
  background-color: #ff5a5f;
  border: none;
}

.text-left img {
  padding: 20px;
}

.brandPageConatiner {
  margin-top: 40px;
}

.classRow {
  margin-top: 30px;
}

input,
label,
option,
select,
i,
li,
ol,
ul,
button,
bold,
strong,
a,
p {
  font-family: 'Omnes', sans-serif;
  color: #333333;
}

.secondFotRow {
  margin-top: 52px !important;
}

.logo {
  /* position: absolute; */
  /* width: 171px;
  height: 38px; */
  /* left: 120px; */
  /* top: 37px; */
}

.logoLeft {
  width: 30%;
  float: left;
}

h2 {
  font-family: "New Spirit", sans-serif;
}

.header {
  width: 100%;
  float: left;
  height: auto;
  font-weight: bold;
  height: auto;
  padding: 0;
  margin-top: 10px;
  color: black;
  padding-bottom: 15px;
  padding-top: 15px;
  font-family: 'Omnes', sans-serif;
}

footer {
  background: #f9f9f9;
  padding: 25px;
  margin-top: 50px;
  overflow: hidden;
}

.solution {
  border-bottom: solid #ead7ff 5px;
}

.btpLogo {
  width: 9rem;
  margin-bottom: 30px;
  margin-top: 30px;
}

.btpPara {
  width: 40%;
  margin-left: 30%;
  margin-top: 30px;
}

.advertising {
  width: 60%;
  margin-left: 20%;
}

.middle {
  margin-top: 30px;
}

.brandLeft {
  margin-top: -20px;
}

#towardLeft1 {
  margin-top: -20px;
}

.take-step-homepage-barndlabs {
  width: 100% !important;
  /* overflow: hidden; */
  /* float: left; */
}

.simple-tools-for-every {
  width: 100% !important;
  overflow: hidden;
  float: left;
  /* margin-top: 100px; */
}

ul {
  list-style: none;
}

.footer-col {
  width: 25%;
}

.footer-col h4 {
  font-size: 18px;
  color: #999999;
  margin-bottom: 30px;
  margin-left: 33px;
}

.footer-col h4::before {
  height: 2px;
  box-sizing: border-box;
  width: 50px;
  color: #999999;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #333333;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #333333;
  padding-left: 8px;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  border-radius: 50%;
  color: #333333;
  transition: all 0.5s ease;
  margin-left: 30px;
}

#parentsLeft {
  margin-top: 100px;
}

#parentsLeft h1 {
  font-size: 54px;
}

#parentsLeft h4 {
  padding: 100px 0;
}


#parentsLeft p {
  padding: 10px;
  padding-left: 0px;
  font-size: 22px;
}

#brandLeft {
  margin-top: 100px;
  float: left;
}

#preciseRight {
  margin-top: 100px;
}

#buildLeft {
  margin-top: 40px;
  margin-left: 25%;
}

.imgTag {
  background-image: url("../img/Group\ 2050.png");
  width: 80%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 10%;
  height: 100%;
  overflow: hidden;
}

.loginImage {
  width: 90%;
  overflow: hidden;
  margin-top: 20px;
}

.perfectPic {
  width: 40%;
  height: 40%;
  overflow: hidden;
}

#towardLeft {
  width: 100%;
  height: 400px;
  background-color: #fef8dc;
}

.towardLeft1 {
  margin-top: 100px;
}

.circleBuild {
  width: 200px;
  height: 200px;
  background-color: #fff0fe;
  overflow: visible;
  margin-top: 100px;
  border-radius: 100%;
}

.circleBuild1 {
  position: absolute;
  color: #24262b;
  padding: 50px;
}

.dot {
  width: 18px;
  height: 18px;
  background-color: #ed4d41;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  justify-content: space-between;
  margin-left: 1px;
}

.dot1 {
  width: 18px;
  height: 18px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: inline-block;
  justify-content: space-between;
  padding: 5px;
}

.vectorLogo {
  width: 5%;
  margin-top: 24px;
  margin-bottom: 2%;
}

.logoFooter {
  width: 100px;
}

.logoFooter1 {
  width: 100px;
  margin-left: 106px;
  margin-top: -58px;
}

.buildRelationship {
  margin-left: 10px;
}

.footerConatct {
  margin-left: 10px;
}

.imgParents {
  margin-left: 10%;
  width: 80%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: 100%;
}

#toolkitImg {
  margin-right: 100px;
}

.toolkitH3 {
  margin-left: 100px;
  margin-top: 07px;
}

.toolkitP {
  margin-left: 140px;
}

.toolkitP1 {
  margin-left: 48px;
  margin-top: -20px;
}

#toolkitImgMiddle {
  margin-right: 220px;
}

.toolkitMiddleH3 {
  margin-right: 05px;
  margin-top: 07px;
}

.middleToolkitP {
  margin-right: 20px;
}

.middleToolkitp1 {
  margin-right: 145px;
  margin-top: -20px;
}

.preciseUl {
  list-style: square;
  color: black;
}

.mobile {
  display: none;
}

.pc {
  display: block;
}

.btnLogin {
  align-items: center;
  gap: 7.32px;
  border: none;
  margin-top: 8px;
  width: 110px;
  height: 50px;
  background: #ff5a5f;
  border-radius: 12px;
  font-family: "Omnes", sans-serif;
  font-weight: bold;
}

.copyrightFt {
  margin-top: 50px !important;
  margin-bottom: -10px !important;
  opacity: 60%;
}

.imgCeo {
  border-radius: 50%;
  width: 106px;
  height: 107px;
  overflow: hidden;
}

.navbar-toggler-icon {
  color: #333333;
}

.preciseCircle {
  width: 455px;
  height: 455px;
  border-radius: 50%;
  background: #e8fff2;
  text-align: left;
}

.brandColor {
  color: #ff5a5f;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
}

.half-circle {
  width: 156px;
  height: 398px;
  background-color: #e8fff2;
  border-bottom-right-radius: 129px;
  border-top-right-radius: 129px;
  border-left: 0;
  box-sizing: border-box;
  margin-bottom: -362px;
  margin-left: -12px;
  margin-top: 33px;
}

.dabarPic {
  margin-top: -106px;
  width: 90%;
  overflow: hidden;
}

.wantsToTalk {
  width: 100%;
  background-color: #f26358 !important;
  height: 341px;
  margin-left: auto;
  border-radius: 25px;
  box-shadow: 0.01rem 0.1rem 0.3rem;
  margin-top: 40px;
  padding: 23px;
  overflow: hidden;
  margin-bottom: 100px !important;
}

.parentWantPic {
  z-index: 1;
  margin-top: -21%;
  margin-left: -4%;
  width: 105%;
  position: absolute;
  bottom: 0;
}

.pages {
  margin-left: 10% !important;
}

.parentsHeading {
  color: #f0f0f0;
  margin-top: 50px;
  margin-left: 30px;
  font-size: 28px;
}

.btnAdvertise {
  z-index: 2;
  margin-left: 30px;
  font-size: 28px;
  background: #f0f0f0;
  color: #ed4d41;
  font-weight: 300;
  font-size: 22px;
  font-family: bl-regular;
  padding: 10px;
}

.brandLeftPara {
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #333333;
  font-weight: 500 !important;
  width: 71%;
}

.card1 {
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 25px;
  height: 250px;
  overflow: hidden;
}

.card2 {
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 25px;
  height: 250px;
  overflow: hidden;
}

.containerBuild p {
  text-align: left;
  width: 400px;
  font-family: bl-regular;
}

@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
    overflow: hidden;
  }
}

@media (max-width: 574px) {
  .footer-col {
    width: 100%;
    overflow: hidden;
  }
}

.linkPages {
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 46px;
  color: #666666;
  font-family: bl-regular;
}

.linkPages:hover {
  color: #333333;
}

@media only screen and (max-width: 360px) {
  .btnLogin {
    margin-left: 117% !important;
    height: 37px;
    text-align: center;
    background-color: #ff5a5f;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    font-family: bl-regular;
    margin-top: 2px;
    border: none !important;
    width: 90px !important;
    color: #e8fff2;
  }

  .parentWantPic {
    margin-top: -48% !important;
    margin-left: -25% !important;
    width: 139%;
    height: 100%;
    padding: 6% !important;
    overflow: hidden;
  }
}

@media (max-width: 1100px) {
  .parentsDoBold h1 {
    font-family: bl-thin !important;
  }

  .headerLogo {
    position: absolute;
    width: 140px;
    top: 30px;
  }

  .pages {
    margin-left: 8% !important;
  }

  .secondFotRow {
    margin-top: -47px !important;
  }

  .doMore {
    margin-top: 30px;
    margin-left: -40%;
  }

  body {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .imgCeo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }

  .card1 {
    margin-top: 13px;
    overflow: hidden;
    float: left;
    margin-top: 13px;
    overflow: hidden;
    float: left;
  }

  .card2 {
    margin-top: 10px;
  }

  .yourPer {
    width: 100%;
  }

  .imgTag {
    width: 85%;
    overflow: hidden;
    background-repeat: no-repeat;
    text-align: center;
    margin-left: 10%;
    background-image: url("../img/Group\ 2122.png");
    height: 100%;
    padding: 20px;
    margin-bottom: -10px;
  }

  .goals {
    width: 100% !important;
    background-color: #fef8dc !important;
  }

  .goals container {
    padding: 0;
  }

  #towardLeft {
    width: 100%;
    height: 700px;
    background-color: #fef8dc;
  }

  #preciseRight {
    margin-top: -20px;
  }

  .leftRes {
    text-align: left;
    margin-left: -20%;
    font-family: bl-regular;
    font-weight: 300;
    font-size: 32px;
    width: 95%;
  }

  .leftRes1 {
    margin-left: -20%;
    text-align: left;
  }

  .leftGoals {
    text-align: left;
    margin-left: -20%;
    font-family: bl-regular;
    font-weight: 300;
    font-size: 32px;
    line-height: 150%;
    width: 300px;
    overflow: hidden;
  }

  .leftGoals1 {
    margin-left: -74%;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    overflow: hidden;
  }

  .containerDoMore {
    margin-left: -4%;
    text-align: left;
    width: 100%;
  }

  .img-responsive {
    display: none;
    overflow: hidden;
  }

  .imgParents {
    width: 80%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
  }

  .btnLogin {
    margin-left: 117% !important;
    height: 40px;
    text-align: center;
    background-color: #ff5a5f;
    border-radius: 10px;
    overflow: hidden;
    font-family: bl-regular;
    border: none;
    margin-top: 2px;
    border: none !important;
    padding: 16px 86px !important;
    width: 95px;
    color: #e8fff2;
    font-weight: 400;
  }

  .navbar-toggler {
    border: none !important;
    color: transparent;
    overflow: hidden;
  }


  .logoLeft img {
    width: 34%;
    float: left;
    margin-top: -2px;
    overflow: hidden;
  }

  .circleBuild1 {
    float: left;
    position: absolute;
    color: #24262b;
    text-align: left;
    overflow: hidden;
  }

  .circleBuild {
    width: 200px;
    height: 200px;
    background-color: #fff0fe;
    overflow: visible;
    margin-top: 10px;
    border-radius: 100%;
    margin-left: -55px;
    overflow: hidden;
  }

  .familyImage {
    margin-top: 35px;
    margin-left: 3px;
    overflow: hidden !important;
    width: 100% !important;
  }

  .reg {
    width: 99%;
  }

  .reg1 {
    width: 99%;
    margin-top: 10px;
    background-color: #ed4d41;
  }

  .loginImageMobile {
    width: 90%;
    overflow: hidden;
    padding: 2px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .parentsDoPlay {
    margin-top: -50px;
  }

  p.btpPara {
    text-align: center;
    font-size: 14px;
    line-height: 1.8rem;
    color: #333333;
  }

  .btpPara {
    text-align: left;
    width: 100%;
    margin-left: 0px;
  }

  .perfectPic {
    width: 100% !important;
    margin-left: -20px !important;
  }

  .middle {
    width: 100%;
    margin-left: -19%;
    font-size: 16px;
    margin-top: 70px;
    color: #333333;
  }

  .advertising {
    text-align: left;
    width: 100%;
    font-family: bl-regular;
    color: #282828;
    margin-left: 4%;
    line-height: 150%;
    letter-spacing: 0.04em;
  }

  #brandLeft h2 {
    font-size: 28px !important;
  }

  #brandLeftPara p {
    font-size: 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    font-family: bl-regular;
    font-weight: 400 !important;
  }

  #brandLeft {
    margin-top: 20px;
  }

  .circleBuild {
    width: 200px;
    height: 200px;
    background-color: #fff0;
    overflow: visible;
    margin-top: 23px;
    border-radius: 100%;
    margin-left: -55px;
  }

  .circleBuildMobile {
    width: 172px;
    height: 172px;
    overflow: hidden;
    background: #e7d3e5;
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
    border-left: 0;
    box-sizing: border-box;
    margin-bottom: -410px;
    margin-left: 269px;
    margin-top: 1px;
  }

  .dot {
    margin-top: 10px;
  }

  .half-circle {
    width: 104px;
    height: 296px;
    background-color: #e8fff2;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    border-left: 0;
    box-sizing: border-box;
    margin-bottom: -267px;
    margin-left: 12px;
  }

  .dabarPic {
    margin-top: -13%;
    width: 90%;
    overflow: hidden;
    margin-left: 13%;
  }

  .footer-col ul li a {
    float: left;
    color: #ffffff;
    text-decoration: none;
    color: #333333;
    padding: 15px;
    transition: all 0.3s ease;
    margin-left: -20px;
  }

  .parentsHeading {
    color: #f0f0f0;
    margin-top: 20px;
    margin-left: -2px;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    font-family: bl-regular;
  }

  .btnAdvertise {
    z-index: 3 !important;
    margin-left: -2px;
    font-size: 28px;
    background: #f0f0f0;
    color: #ed4d41;
    font-weight: 300;
    font-size: 22px;
    font-family: bl-regular;
    padding: auto;
    border-radius: 10px;
  }

  .btnAdvertise:hover {
    cursor: pointer !important;
  }

  .logoFooter1 {
    width: 100px;
    margin-left: -217px;
    margin-top: 11px;
  }

  .logoFooter {
    width: 100px;
    margin-top: 10px;
    margin-left: -20px;
  }

  .download {
    color: #333333 !important;
    margin-left: -18px;
    float: left;
    margin-top: -20px;
  }

  .downloadOur {
    margin-left: -1%;
  }

  .vectorLogo {
    width: 40px;
  }

  .copyrightFt {
    opacity: 60%;
    padding: 3px;
    margin-left: 5%;
  }

  .containerBuild {
    margin-top: -16%;
  }

  .buildCircle {
    margin-top: -16%;
  }

  .hrm {
    width: 700px;
    margin-left: -200px !important;
    overflow: hidden;
  }

  .card.border-0.wantsToTalk {
    overflow: hidden;
  }

  .wantsToTalk {
    background-color: #f26358;
    height: 280px;
    width: 95%;
    margin-right: 3%;
    padding: 2%;

    overflow: hidden;
  }

  .parentWantPic {
    margin-top: -53%;
    margin-left: -23%;
    width: 139%;
    height: 100%;
    padding: 2%;
    overflow: hidden;
  }
}

.familyImage {
  width: 90%;
  overflow: hidden;
}

@media (max-width: 1100px) {
  body {
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navbar-toggler.collapsed:focus {
    border: none !important;
  }

  .navbar-toggler:focus-visible {
    border: none !important;
  }

  .mobile {
    display: block;
    padding: 0px !important;
    margin-left: -20px;
  }

  .pc {
    display: none;
  }

  .parentsDoBold {
    font-size: 32px !important;
  }

  .parentsDoBold h1 {
    font-family: "New Spirit" !important;
  }

  .parentsDoPlay h4 {
    font-size: 14px !important;
  }

  .btn.btn-outline-danger.btn-lg.custom-btn.reg {
    padding: 0px !important;
    height: 45px;
    border: solid 2px;
    font-family: bl-regular;
    font-size: 16px;
  }

  .btn.btn-danger.btn-lg.custom-btn.lgn.reg1 {
    padding: 0px;
    height: 45px;
    font-family: bl-regular;
    font-size: 16px;
    border: none;
    background-color: #ff5a5f !important;
  }

  .reg1 {
    background-color: #ff5a5f !important;
  }

  .container.text-center.yourPerfect {
    margin-top: 40px;
  }

  img.btpLogo {
    width: 30%;
    margin-bottom: 40px;
    overflow: hidden;
  }
}

@media (max-width: 320px) {
  .btnLogin {
    margin-left: 96% !important;
  }

  .parentWantPic {
    margin-top: -51% !important;
    width: 149%;
    height: 100%;
    overflow: hidden;
    margin-left: -31% !important;
  }
}

h2#head {
  text-align: center;
  padding: 10px;
}

p#pead {
  padding: 20px;
  text-align: center;
}

.circle {
  width: 100px;
  height: 100px;
  padding: 10px;
  background-color: white;
  overflow: hidden;
  text-align: center;
  float: left;
  border-radius: 100%;
}

.line {
  width: 100px;
  overflow: hidden;
  height: 1px;
  margin-left: -4px;
  margin-top: 52px;
  border: solid rgba(244, 182, 25, 0.25) 1px;
  float: left;
  z-index: -10;
}

.right-line {
  float: left;
  width: 20px;
  height: 20px;
  background-color: white;
  margin-top: 44px;
  margin-left: -14px;
  overflow: hidden;
  z-index: -10;
  transform: rotate(45deg);
}

.takeSteps {
  margin-top: 40px;
}

p#on-p {
  float: left;
  margin-top: 40px;
  overflow: visible;
  position: absolute;
  text-align: center;
  font-weight: 900;
  width: 150px;
  height: 60px;
  margin-left: -36px;
}

@media (max-width: 1100px) {
  .onboarding {
    float: left;
    margin-left: -18%;
    background-color: #fef8dc;
    padding: 28%;
    margin-top: -24%;
  }

  .circle {
    width: 44%;
    height: 44%;
    padding: 7%;
    background-color: white;
    overflow: hidden;
    text-align: center;
    float: left;
    border-radius: 100%;
  }

  .line {
    width: 100px;
    height: 100px;
    margin-left: 22%;
    margin-top: 0px;
    border: none;
    border-left: solid rgba(244, 182, 25, 0.25) 2px;
    float: left;
    z-index: -10;
  }

  .right-line {
    width: 20px;
    height: 20px;
    background-color: white;
    margin-top: 85px;
    margin-left: -26%;
    overflow: hidden;
    transform: rotate(36deg);
  }

  p#on-p {
    float: left;
    margin-top: -12%;
    overflow: visible;
    position: absolute;
    text-align: center;
    font-weight: 900;
    margin-left: 30%;
  }
}

.onboarding {
  margin-left: 10%;
  float: left;
  width: 100%;
}

button.add-section-btn {
  margin-bottom: 50px;
}

.brand-pages-container {
  width: 100%;
  float: left;
  overflow: hidden;
}

.brand-page-main-section {
  background: linear-gradient(180deg, #e9392c 0%, #ee564a 33.09%);
  text-align: center;
}

.brand-page-section {
  height: "100%";
  padding: 50px;
  text-align: center;
  width: 60%;
  margin-left: 20%;
  overflow: hidden;
  padding-bottom: 0px;
}

.product-page-section {
  height: "100%";
  padding: 50px;
  text-align: center !important;
  width: 60%;
  overflow: hidden;
  padding-bottom: 0px;
}

.product-page-section h1 {
  font-family: bl-medium;
  color: white;
  font-size: 58px;
  margin-top: 20px;
}

.product-page-section p {
  font-size: 24px;
  color: white;
  margin-top: 10px;
  width: 60%;
  text-align: center;
  margin-left: 20%;
}

.product-page-section button {
  height: 65px;
  background: #ffffff;
  border: none;
  font-size: 20px;
  color: #ff5e3a;
  border-radius: 12px;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  font-family: "bl-bold";
  margin-top: 100px;
  text-align: center;
  margin-left: 40%;
  width: 20%;
  margin-top: 0px;
}

.brand-page-section-third {
  height: "100%";
  padding: 50px;
  text-align: center;
  width: 60%;
  margin-left: 20%;
  overflow: hidden;
  padding-bottom: 0px;
}

.brand-page-section-third h1 {
  font-family: bl-medium;
  color: #ff5e3a !important;
  font-size: 58px;
  overflow: hidden;
}

.brand-page-section-third p {
  font-size: 24px;
  color: white;
  margin-top: -40px;
}

.brand-page-section-third button {
  width: 200px;
  padding: 18px;
  border: none;
  font-family: bl-medium;
  border-radius: 14px;
  background: linear-gradient(0deg, #ff2a68 0%, #ff5e3a 100%);
  padding: 16px 36px;
  gap: 12px;
  color: white;
  box-shadow: 0px 0px 10px 25px (#000000);
}

.brand-page-section h1 {
  font-family: bl-medium;
  color: white;
  font-size: 58px;
  margin-top: 20px;
}

.brand-page-section p {
  font-size: 24px;
  color: white;
}

.brand-page-section button {
  width: 40%;
  padding: 18px;
  border: none;
  font-family: bl-medium;
  border-radius: 14px;
  margin-left: 30%;
  box-shadow: 0px 0px 10px 25px (#000000);
}

.brand-page-section-third img {
  width: 100%;
}

.booo {
  margin-top: 100px;
}

.brand-page-section img {
  width: 100%;
  margin-top: 100px;
  margin-bottom: -25px;
  margin-top: 145px;
  overflow: hidden;
}

.product-page-second-section {
  background-color: white;
}

.product-page-second-section h1 {
  color: #333333;
  padding: 50px;
  font-size: 50px;
}

.product-page-second-section p {
  color: #333333;
}

.brand-page-second-section {
  background-color: white;
  color: #333333;
}

.brand-page-second-section h1 {
  color: #333333;
  margin-top: 60px;
  margin-bottom: 40px;
}

.brand-page-second-section p {
  color: #666666;
}

.full-width {
  width: 100%;
  float: left;
  padding: 50px;
  background-color: #ffffff;
  margin-top: 200px;
  height: 100%;
}

.full-width-question {
  width: 100%;
  float: left;
  padding: 50px;
  background-color: #ffffff;
  height: 100%;
}

.inner-full-width {
  width: 100%;
  float: left;

  overflow: hidden;
}

.left-width {
  width: 60%;
  float: left;
  margin-top: 160px;
}

.left-width-discovered {
  width: 60%;
  float: left;
}

.left-width-customer {
  width: 60%;
  float: left;
}

#normal {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #333333;
  flex: 100%;
  font-family: bl-regular;
}

.left-width p {
  font-family: "bl-regular";
  font-style: normal;
  width: 100%;
  flex: 100%;
  font-weight: 400;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #999999;
  margin-top: 20px;
  width: 80%;
}

.left-width h2 {
  margin-top: 20px;
}

.right-width {
  width: 40%;
  float: left;
}

.right-width-customer {
  width: 40%;
  float: left;
}

.right-width-discovered {
  width: 40%;
  float: left;
}

.inner-left-customer {
  border: none;
  border-radius: 48px;
  padding: 33px;
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  margin-right: 5%;
  overflow: hidden;
}

.inner-right-width-discovered {
  border: none;
  border-radius: 48px;
  padding: 33px;
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  margin-right: 5%;
}

.inner-right-width {
  width: 70%;
  float: right;
  overflow: hidden;
  margin-top: 0px;
  height: 140px;
}

img#section-five-banner {
  width: 90%;
  margin-left: 5%;
}

.inner-left-width {
  float: left;
  overflow: hidden;
  width: 60%;
  height: 150px;
}

.inner-left-width img {
  width: 45px;
  height: 45px;
  float: left;
  margin-top: 100px;
}

.inner-left-width p {
  width: 70%;
  background: #ffffff;
  border-radius: 25px;
  border: 2px solid #f0f0f0;
  padding: 10px;
  margin-left: 60px;
  font-family: bl-regular;
  color: #333333;
  line-height: 130%;
  padding-left: 20px;
  margin-top: 60px;
}

.inner-right-width p {
  width: 70%;
  background: #f0f0f0;
  border-radius: 25px;
  border: 2px solid #f0f0f0;
  padding: 10px;
  margin-left: 70px;
  font-family: bl-regular;
  color: #333333;
  line-height: 130%;
  padding-left: 20px;
}

.inner-title {
  font-family: bl-bold;
  font-size: 40px;
}

.inner-title-review {
  font-family: bl-bold;
  font-size: 40px;
  width: 100%;
}

.inner-right-width img {
  float: right;
  margin-top: -40px;
  width: 45px;
  height: 45px;
  border: 1.92812px solid #f0f0f0;
  border-radius: 100%;
}

.inner-left-discovered-review {
  height: auto;
  background: #ffffff;
  backdrop-filter: blur(110px);
  border-radius: 48px;
  border: none;
  margin-left: 10%;
  padding: 23px;
  width: 80%;
  overflow: hidden;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
}

.inner-left-discovered {
  height: auto;
  background: linear-gradient(180deg,
      rgba(153, 212, 234, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 42.71%,
      rgb(243, 36, 36) 100%);
  backdrop-filter: blur(110px);
  border-radius: 48px;
  border: none;
  margin-left: 10%;
  padding: 23px;
  width: 80%;
  overflow: hidden;
}

.inner-left-discovered img {
  width: 100%;
  margin-bottom: -15%;
  margin-left: -11%;
}

.upload-text {
  font-size: 30px;
  font-family: bl-regular;
}

.inner-width-collapse {
  margin-left: 8%;
}

.inner-width-collapse h2 {
  color: #333333;
  font-size: 48px;
  font-family: bl-bold;
  margin-bottom: 100px;
}

.inner-width-collapse li {
  font-size: 24px;
  font-family: bl-bold;
  margin: 10px;
  width: 600px;
}

.inner-width-collapse ol {
  margin: 20px;
}

#example-collapse-text {
  font-size: 16px;
  color: #333333;
  font-family: bl-regular;
}

.inner-width-collapse span {
  display: flex;
}

.btn-collapse {
  margin-left: 10%;
  background-color: transparent;
  color: #333333;
  font-size: 30px;
  border: none;
}

button.btn-collapse[aria-expanded="true"] .plus {
  display: none;
}

button.btn-collapse[aria-expanded="false"] .close {
  display: none;
}

#slideset3 img {
  width: 620px;
  height: 620px;
}

.brand-page-getstarted-section {
  background: linear-gradient(91.44deg,
      #f04336 0%,
      rgba(240, 77, 65, 0.92) 86.41%);
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  margin-top: 200px;
  float: left;
  margin-bottom: 100px;
  overflow: hidden;
}

.product-page-getstarted-section {
  background: linear-gradient(91.44deg,
      #f04336 0%,
      rgba(240, 77, 65, 0.92) 86.41%);
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  margin-top: 200px;
  float: left;
  margin-bottom: 100px;
  overflow: hidden;
}

.inner-img-caursol {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  animation-name: example;
  animation-duration: 2s;
}

/* .carousel-control-next-icon,
.carousel-control-prev-icon {
  visibility: hidden;
  background-repeat: no-repeat;
}

.carousel-indicators li {
  display: none;
}

.carousel-indicators {
  display: none !important;
} */

.inner-width-collapse svg {
  position: relative;
}

.footer {
  background: #f9f9f9;
  padding: 25px;
  margin-top: 0px;
  float: left;
  width: 100%;
  margin-bottom: -50px;
}

.main-first-container {
  background: linear-gradient(180deg, #e9392c 0%, #ee564a 33.09%);
  text-align: center;
}

.get-started-btn {
  float: left;
  width: 300px;
  overflow: hidden;
  margin-bottom: 100px;
}

.get-started-btn button {
  width: 200px;
  float: left;
  border: soild #ffffff 2px;
  margin-left: 0px;
}

button#btn-one {
  float: left;
  background-color: transparent;
  color: white;
  margin-left: 50px;
  border: solid #ffffff 2px;
}

button#btn-two {
  background-color: #ffffff;
  position: absolute;
  float: left;
  border: solid #ffffff 2px;
}

.right-width img {
  position: absolute;
  width: 640px;
  z-index: 9;
}

/* Solution page */
.solution-page-conatiner {
  width: 100%;
}

.solution-full-width {
  width: 100%;
  float: left;
  padding: 80px;
  background: #fff6f5;
}

.solution-left-width {
  width: 40%;
  float: left;
}

.solution-right-width {
  width: 60%;
  float: left;
}

solution-right-image img {
  width: 100%;
}

.solution-first-left-div p {
  width: 458px;
  height: 280px;
  font-family: "bl-medium";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 4rem;
  letter-spacing: 0.02em;
  margin-top: 70px;
}

.solution-first-left-div button {
  padding: 16px 86px;
  width: 260px;
  height: 65px;
  background: #ed4d41;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  color: #ffffff;
  font-family: "bl-medium";
  border: none;
  margin-top: 20px;
}

.solution-first-left-div span {
  color: #ed4d41;
  font-family: "bl-medium";
}

.second-inner-div {
  margin-top: 70px;
  background: linear-gradient(180deg, #ed4d41 0%, #ff9635 70.1%, #ff9635 100%);
  border-radius: 24px;
  width: 100%;
  height: auto;
  padding: 30px;
  text-align: center;
}

.second-inner-div h1 {
  color: #ffffff;
  font-family: "bl-bold";
}

.second-inner-div p {
  font-family: bl-regular;
}

.introducing-card {
  display: flex;
  padding: 10px;
  margin-left: 20px;
  text-align: center;
  margin-left: 7%;
}

.solution-card {
  color: #333333;
  font-family: bl-regular;
  height: 340px;
  text-align: center;
  border-radius: 33px;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  width: 100% !important;
}

.solution-card img {
  width: 120px;
  height: 120px;
  text-align: center;
  margin-left: 30%;
  margin-top: 10%;
}

.solution-card p {
  font-family: bl-regular;
  font-size: 20px;
}

.solution-card h3 {
  color: #333333;
  font-family: "bl-medium";
}

#p-comp {
  color: #ffffff !important;
  font-size: 14px;
  font-family: bl-regular;
}

.our-solution-div {
  margin-top: 40px;
  width: 100%;
}

.our-solution-div h1 {
  font-family: bl-medium;
}

.left-width-solution {
  width: 60%;
  float: left;
  margin-top: 80px;
}

.right-width-solution {
  width: 40%;
  float: left;
  margin-top: 100px;
}

.boost-brand {
  margin-top: 30px;
}

.boost-inner-card {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 30px;
}

.boost-inner-card1 {
  background: #ffffff;
  box-shadow: 0px 5px 31px rgba(49, 49, 49, 0.1);
  border-radius: 36px;
  border: none;
  padding: 30px;
  margin-top: 10px;
}

.right-width-solution span {
  color: #ed4d41;
}

img.right-header-img {
  max-height: 150px;
  border-radius: 10px;
  object-fit: cover;
}

nav.header.navbar.navbar-expand-lg.navbar-light {
  position: fixed;
  top: 0;
  background: white;
  margin-top: 0px;
  float: left;
  width: 100%;
  z-index: 999;
  border-bottom: 2px solid #eee;
}

.navbar-brand span {
  font-size: 40px;
  position: relative;
  display: inline-block;
  top: 10px;
}

div#top-gap {
  margin-top: 80px;
}

/* container 100% width temp if screen breaks remove this css */
@media (min-width: 1400px) {
  .container {
    max-width: 90%;
  }
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 15px;
}

#copyright {
  margin-bottom: 50px;
  margin-top: 50px;
  font-size: 16px;
}

#product-bg {
  width: 100% !important;
  margin-top: 40px;
}

.full {
  width: 100%;
  margin-left: 0%;
}

.review-text {
  text-align: center !important;
  color: #666666;
}

.review-text label {
  width: 100%;
  height: 96px;
  text-align: center;
  font-size: 60px;
  color: #666666;
  font-family: "bl-medium";
}

.review-text p {
  font-size: 28px;
  color: #666666;
  font-family: "bl-regular";
}

.brand-sec-img-inner {
  width: 300px;
  height: 300px;
  position: absolute;
  float: right;
  margin-top: -800px;
}

.product-page {
  overflow: hidden;
}

.brand-sec-img-inner img {
  width: 100%;
  height: auto;
}

#btn-badge {
  padding: 16px 48px;
  gap: 12px;
  width: 228.74px;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 1px 34px rgba(136, 136, 136, 0.1);
  border-radius: 12px;
  text-align: center;
}

#king_badge {
  width: 20px;
  height: 20px;
  margin-right: 20px !important;
  text-align: center;
}

.product-page-section-second {
  width: 100%;
  text-align: center;
}

.product-page-section-second h1 {
  font-size: 58px;
  color: #333333;
  font-family: "bl-medium";
  margin-top: 20px;
}

.product-page-section-second p {
  width: 60%;
  font-size: 24px;
  color: #666666;
  margin-left: 20%;
  margin-top: -10px;
  font-family: "bl-regular";
}

.build-product-bg {
  margin-top: -17% !important;
}

.product-page-second-section-do {
  width: 100%;
  text-align: center;
}

.product-do-more-section {
  margin-top: 12% !important;
  width: 100%;
}

.product-do-more-section h1 {
  font-family: "bl-medium";
  font-size: 58px;
  width: 100%;
}

.product-do-more-section p {
  font-size: 24px;
  color: #666666;
  padding: 20px;
  text-align: center;
  font-family: "bl-regular";
  width: 60%;
  margin-left: 20%;
}

.react-stars-wrapper-012194627292277604 {
  text-align: center;
  margin-left: 50% !important;
  /* margin-left: -100px */
}

.gather {
  font-size: 26px;
  width: 95%;
  font-family: "bl-regular";
  color: #333333;
  text-align: left;
  overflow: hidden;
  padding-right: 20px;
}

.undefined.react-stars {
  width: 100%;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

button#create-own-page {
  color: #ed4d41;
  position: absolute;
  float: left;
  border: solid #ffffff 2px;
  border: none;
}

.qu_and_ans img {
  margin-left: 60%;
  margin-top: -30px;
}

.have-any-question {
  background: #f9f9f9;
}

.tools-for-everyone {
  text-align: center;
  margin-top: 10%;
}

.download-img {
  display: flex;
  float: left;
  margin-left: 0%;
}

.download-img img {
  width: 100px;
}

/* image zoom */
.hero-section {
  width: 100%;
  height: 70rem;
  overflow: hidden;
  position: relative;
}

.hero-section img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.animated_image {
  margin-top: 40px;
}

.animated_image img {
  margin-top: 100px;
  margin-bottom: -30px;
  height: auto;
}

.slide_brand_inner {
  background-color: #e9392c !important;
  width: 700px;
  height: 660px;
  border-radius: 23px;
  width: 160%;
}

.react-tel-input .form-control {
  position: relative !important;
  font-size: 14px !important;
  letter-spacing: 0.01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  background: #ffffff !important;
  border: 1px solid #cacaca !important;
  border-radius: 5px !important;
  line-height: 25px !important;
  height: 47px !important;
  width: 100% !important;
  outline: none;
}

.otp {
  display: flex;
  justify-content: left;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  margin-left: 22px;
}

.otp div {
  margin-left: -10px;
}

.otp_verify {
  display: flex;
  /* justify-content: left; */
  margin-top: 20px;
  width: 100%;
  align-items: center;
  /* margin-left: 22px; */
  margin-bottom: 10px;
}

.otp_verify div {
  margin: auto;
  /* margin-left: -10px; */
}

.inputStyle {
  width: 64px !important;
  height: 64px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #fcfcfc;
  font-size: 20px;
  border: 1px solid #afafaf;
}

/*  */

.onboarding-nav {
  position: fixed;
  /* Fix the side navigation to the viewport */
  top: 0;
  /* Align the top edge with the viewport top */
  left: 0;
  /* Align the left edge with the viewport left */
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  min-height: 100vh;
  /* Ensure the height is at least 100vh */
  background: #f9f9fe;
  /* Set the background color */
  overflow-y: auto;
  width: 300px;
  border-right: 1px solid rgba(171, 171, 171, 0.174);
}

.onboard-icon {
  font-size: 3.5rem;
  color: var(--bs-primary);
}

.upload-bg {
  height: -webkit-fill-available;
}

.ant-upload.ant-upload-select-picture-card {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.text-btn {
  color: #ff5a5f;
  font-family: var(--btp-gilroy-medium);
  /* margin-bottom: 1.5rem; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 128.571% */
  border: none;
  background: transparent;
  box-shadow: none;
}

.otp-box input {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 0.5rem;
  border: 1px solid #dddddd;
  margin-right: 2 !important;
}

.onboard-card:hover {
  border: 2px solid var(--bs-primary);
  /* box-shadow: 0px 1px 1px #ff5a5f; */
}

.criclebox.onboard-card-selected {
  border: 2px solid var(--bs-primary);
  background-color: var(--bs-active-bg);
}

.checked-fill {
  color: var(--bs-primary);
}

.onboard-slider-text {
  line-height: 20px;
  font-weight: 400;
  /* letter-spacing: 1.3px; */
}

.onboard-progress-bar {
  bottom: 0;
  /* position: absolute; */
  width: -webkit-fill-available;
}

.onboard-progress {
  color: #ff5a5f;
}

.onboarding-nav-content {
  position: relative;
  /* Position the main content relative to its container */
  left: 280px;
  padding-block: 2rem;
  width: calc(100% - 350px);
  /* Set the width to 100% minus the side navigation width */
  /* overflow-y: auto; */
  /* height: 100vh; */
  /* Set the height to 100% of the viewport height */
  min-height: 100vh;
}

.ant-btn:hover,
.ant-btn:focus {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.campaign-creation-flow {
  position: relative !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* .campaign-creation-steps {
  bottom: 0;
  position: fixed;
} */

.campaign-static-list {
  list-style-type: disc;
}

.campaign-static-group {
  bottom: 0;
  margin-bottom: 0;
  position: absolute;
}

.campaign-static-text {
  color: var(--neutral-7, #8c8c8c);
  width: 13.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 166.667% */
  height: 2rem;
  letter-spacing: 0.05px;
  word-spacing: 1px;
}

.ant-picker-range {
  width: 100%;
  border-radius: 6px;
  height: 40px;
}

.budget {
  border-radius: 6px;
  padding-block: 0;
  width: 100%;
  /* height: 40px; */
}

.ant-tree {
  background: transparent;
}

.onboarding-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  margin-right: 40px;
}

/* .campaign-creation-flow */

.ant-select {
  width: 100%;
  border-radius: 6px;
  height: 40px;
}

.ant-select-selector {
  border-radius: 6px !important;
  min-height: 40px !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}

.input-uppercase {
  text-transform: uppercase;
}

.ant-steps-finish-icon {
  color: var(--bs-primary);
}

.ant-steps-item-icon {
  border-color: var(--bs-primary);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  border-color: var(--bs-btn-disabled-border-color);
  background-color: var(--bs-primary);
}


.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: var(--bs-primary);
}

.ad-preview-card {
  max-height: fit-content;
  overflow: "auto";
}

.ad-preview-card .ant-card-cover img {
  max-height: 35vh;
  object-fit: contain;
}

.campaign-btn {
  width: 200px;
}

.campaign-btn-submit {
  width: 120px;
}

.ant-typography strong {
  font-size: 17px;
}

.modal-dialog .modal-dialog-centered {
  width: fit-content;
}

.sub-text {
  letter-spacing: 1px;
}

.ant-tag {
  display: flex;
  align-items: center;
  /* border: none; */
  max-width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
}

.banneredImg {
  width: 100%;
}

.ad-preview-title {
  white-space: normal;
}

.status-style {
  display: "flex";
  align-items: "center";
}

.info-card {
  color: black;
  text-decoration: none;
}

.ant-dropdown-menu {
  border-radius: 6px;
}

.sponsoredPost-dropdown {
  min-width: 9rem;
}

.ant-message-notice-content {
  border-radius: 6px;
}

.info-card:hover {
  color: lightblue;
}

.attachment {
  height: 70px;
  display: block;
}

.image-container {
  position: relative;
  margin-bottom: 25px;
  margin-left: 25px;
  margin-top: 25px;
  width: 25%;
  height: 25%;
}

.image {
  width: 25%;
  height: 25%;
}

.details {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,
      255,
      255,
      0.8);
  /* Semi-transparent white background */
  padding: 8px;
  font-size: 12px;
  color: black;
}

.post-title {
  margin-left: 25px;
}

.ant-btn-default {
  box-shadow: none;
}

.ant-btn-success {
  box-shadow: none;
}

/* .ant-btn:hover,
.ant-btn:focus {
  color: #ff5a5e;
  border-color: #ff5a5e;
  background: #fff;
} */

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
  /* background: #fff; */
}

.uploadfile .ant-upload.ant-upload-select {
  border: none;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.ant-modal-content {
  border-radius: 12px;
}

.ant-btn-loading-icon {
  display: flex !important;
  justify-content: center;
}

.transactions-card {
  min-height: fit-content;
  max-height: 65vh;
  overflow-y: scroll;
}

.ad-carousel-img {
  /* min-width: 100%;
  min-height: 100%; */
  width: 100%;
  height: 15rem;
}

.ad-carousel-container {
  min-width: 80%;
  /* margin-right: 1.5rem; */
}

.youtube-preview {
  width: 100%;
  height: 15rem;
}

.ant-radio-group {
  border-radius: 6px;
}

.ant-radio-group .ant-radio-button-wrapper {
  width: 120px;
  color: white;
  background-color: #773a89;
  text-align: center;
  height: inherit;
}

.ant-steps-item-container:hover {
  color: inherit;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding-inline: 0;
  flex-direction: row;
  padding: 25px;
  /* background: rgb(245, 207, 207); */
}

.sub-footer {
  display: flex;
  flex-direction: column;
}

footer {
  margin: 0;
  padding: 0;
  /* background: transparent; */
}

@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    /* margin: 0 0 20px 20px; */
  }
}

.ant-layout-footer {
  padding: 0px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--bs-active-bg);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--bs-active-bg);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.policy-page {
  margin-inline: 10rem;
  margin-top: 3rem;
  padding-bottom: 1.5rem;
}

.policy-page>h2 {
  margin-block: 2rem;
  text-align: center;
  text-decoration: underline;
}

.policy-page>div {
  font-weight: 300;
}

.policy-page>span {
  font-weight: bold;
}

.policy-page>strong {
  font-weight: bold;
}

.policy-page>.ant-typography ul ul {
  list-style-type: circle;
}

.ant-btn svg {
  margin-right: 0;
}

.policy-page>.brand:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .policy-page {
    margin-inline: 5rem;
  }
}

li {
  font-family: "Open Sans";
}

.landing-page-footer {
  background: #f7ecff;
}

.ant-layout-sider .ant-layout-sider-light .ant-layout-sider-has-trigger .sider-primary .ant-layout-sider-primary {
  background: transparent;
  flex: none;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}

.card-profile-head>.ant-row {
  height: 2rem;
}

.custom-radio-card .ant-radio {
  display: none;
}

.objective-card {
  box-shadow: none !important;
  height: 18rem;
}

.small-title {
  font-weight: 600;
  color: #8c8c8c;
  font-size: 14px;
}

.ongoing-campaign-card .ant-card-head {
  font-size: 20px;
  font-weight: 600;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #773a89;
}

.ant-radio-inner::after {
  background-color: #773a89;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #773a89;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #773a89;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #773a891f;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #773a891f;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #773a891f;
}

.ant-slider-handle {
  border: solid 2px #773a89;
}

.ant-slider-track {
  background-color: #773a891f;
}

.ant-slider:hover .ant-slider-track {
  background-color: #773a89;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #773a89;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed #773a891f;
  border-bottom: 1px dashed #773a891f;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #773a89;
}

/* span.ant-radio + * {
  padding-right: 0;
  padding-left: 0;
} */

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #773a89;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  padding: 16px 24px;
}

#myIframe {
  height: 65vh;
  min-height: fit-content;
}

@media (max-height: 790px) {
  #myIframe {
    height: 75vh;
  }
}

@media (max-height: 700px) {
  #myIframe {
    height: 80vh;
  }
}

.close-text-btn {
  background: transparent !important;
  color: black !important;
  border: none !important;
  cursor: pointer;
}

.ant-table tfoot>tr>td {
  position: relative;
  padding: 16px 24px;
  overflow-wrap: break-word;
}

.header-position {
  text-align: right;
}

/* .custom-carousel .slick-prev, 
.custom-carousel .slick-next {
  font-size: 24px;
  z-index: 1;
  color: #ff0000; 
}

.custom-carousel .slick-prev:hover, 
.custom-carousel .slick-next:hover {
  color: #ff0000; 
  opacity: 1; 
} */

/* .carousel .slick-next.slick-disabled::before{
  opacity: 0.25;
  font-size: 24px;
} */

.ant-carousel .slick-dots li button {
  background: #000000;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #773A89;
}

.ant-dropdown-menu-item {
  padding: 10px 30px;
}

.creative-text{
  color: red;
}